import './bootstrap'
import '../css/app.css'

import type { DefineComponent } from 'vue'
import { createPinia } from 'pinia'
import {
  createApp,
  h,
} from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import * as Sentry from '@sentry/vue'
import VueApexCharts from 'vue3-apexcharts'
import { createI18n } from 'vue-i18n'
import enUS from './locales/en_US.json'
import daDK from './locales/da_DK.json'

const appName = import.meta.env.VITE_APP_NAME !== undefined
  ? import.meta.env.VITE_APP_NAME
  : 'Laravel'

type CreateInertiaAppProps = Parameters<typeof createInertiaApp>[0]
type InertiaAppProps = Parameters<CreateInertiaAppProps['setup']>[0]['props']

type AppProps = InertiaAppProps & {
  initialPage: {
    props: {
      ziggy: {
        url: string
        location: string
      }
      font_awesome: {
        base_url: string
        token: string
        version: string
      }
      edge_backend: {
        base_url: string
      }
      locale: string
    }
  }
}

type MessageSchema = typeof enUS

import.meta.glob([
  '../images/**',
])

const dsn = import.meta.env.VITE_SENTRY_DSN
const environment = import.meta.env.VITE_APP_ENV

createInertiaApp({
  title: title => `${title} - ${appName}`,
  resolve: async name => await (resolvePageComponent(
        `./Pages/${name}.vue`,
        import.meta.glob('./Pages/**/*.vue'),
  ) as Promise<DefineComponent>),
  setup({ el, App, props, plugin }) {
    const appProps = props as AppProps
    const pinia = createPinia()
    const i18n = createI18n<[MessageSchema], 'en_US' | 'da_DK'>({
      legacy: false,
      locale: appProps.initialPage.props.locale,
      messages: {
        en_US: enUS,
        da_DK: daDK,
      },
    })

    // @ts-expect-error Ziggy type is missing
    globalThis.Ziggy = appProps.initialPage.props.ziggy

    const app = createApp({ render: () => h(App, props) })

    Sentry.init({
      dsn,
      app,
      environment,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['localhost', /^https:\/\/amalo\.cloud\//],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

    app
      .use(i18n)
      .use(plugin)
      .use(pinia)
      .use(VueApexCharts)
      .provide('font_awesome_config', {
        base_url: appProps.initialPage.props.font_awesome.base_url,
        token: appProps.initialPage.props.font_awesome.token,
        version: appProps.initialPage.props.font_awesome.version,
      })
      .provide('edge_backend', {
        base_url: appProps.initialPage.props.edge_backend.base_url,
      })

    app.mount(el)
  },
  progress: {
    color: '#4B5563',
  },
})
